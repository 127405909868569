@charset "utf-8";

@font-face {
  font-family: "Roboto";
  src: url(/assets/fonts/roboto-android/Roboto-Regular.ttf);
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: "Roboto";
  src: url(/assets/fonts/roboto-android/Roboto-Italic.ttf);
  font-style: italic;
  font-weight: 400;
}

@font-face {
  font-family: "Roboto";
  src: url(/assets/fonts/roboto-android/Roboto-Medium.ttf);
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: "Roboto";
  src: url(/assets/fonts/roboto-android/Roboto-MediumItalic.ttf);
  font-style: italic;
  font-weight: 500;
}

@font-face {
  font-family: "Roboto";
  src: url(/assets/fonts/roboto-android/Roboto-Bold.ttf);
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: "Roboto";
  src: url(/assets/fonts/roboto-android/Roboto-BoldItalic.ttf);
  font-style: italic;
  font-weight: 700;
}

@font-face {
  font-family: "Source Code Pro";
  src: url(/assets/fonts/source-code/SourceCodeVF-Upright.otf);
  font-style: normal;
}

@font-face {
  font-family: "Source Code Pro";
  src: url(/assets/fonts/source-code/SourceCodeVF-Italic.otf);
  font-style: italic;
}

@font-face {
  font-family: "RedHat Display";
  src: url(/assets/fonts/RedHatDisplay-VariableFont_wght.ttf);
  font-style: normal;
}

@font-face {
  font-family: "RedHat Display";
  src: url(/assets/fonts/RedHatDisplay-Italic-VariableFont_wght.ttf);
  font-style: italic;
}

// Define defaults for each variable.

$base-font-family:
  "Roboto",
  -apple-system,
  BlinkMacSystemFont,
  "Segoe UI",
  Roboto,
  Helvetica,
  Arial,
  sans-serif,
  "Apple Color Emoji",
  "Segoe UI Emoji",
  "Segoe UI Symbol" !default;
$base-font-size: 18px !default;
$base-font-weight: 400 !default;
$small-font-size: $base-font-size * 0.875 !default;
$base-line-height: 1.6 !default;
$code-font-family: "Source Code Pro", monospace !default;
$code-font-size: 0.9rem;
$code-font-weight: 400;
$code-line-height: 1.5em;
$header-font-family: "Roboto", sans-serif;
$header-font-weight: bold;

$spacing-unit: 30px !default;

$text-color: #111 !default;
$background-color: #fdfdfd !default;
$brand-color: #2a7ae2 !default;

$grey-color: #828282 !default;
$grey-color-light: lighten($grey-color, 40%) !default;
$grey-color-dark: darken($grey-color, 25%) !default;

$table-text-align: left !default;

// Width of the content area
$content-width: 800px !default;

$on-palm: 600px !default;
$on-laptop: 800px !default;

// Use media queries like this:
// @include media-query($on-palm) {
//   .wrapper {
//     padding-right: $spacing-unit / 2;
//     padding-left: $spacing-unit / 2;
//   }
// }
@mixin media-query($device) {
  @media screen and (max-width: $device) {
    @content;
  }
}

@mixin relative-font-size($ratio) {
  font-size: $base-font-size * $ratio;
}

// Import partials.
@import "minima/base", "minima/layout", "minima/syntax-highlighting";

pre,
code {
  font-family: $code-font-family;
  font-size: $code-font-size;
  font-weight: $code-font-weight;
  line-height: $code-line-height;
}

h1,
h2,
h3,
h4 {
  font-family: $header-font-family;
  font-weight: $header-font-weight;
}

pre, pre > code {
  background: none;
  padding: 0;
  border-radius: 4px;
}

pre > code {
  padding: 1em;
  display: block;
}

code {
  background: #fee;
  color: #a55;
  border: none;
}

a.site-title {
  font-weight: bold;
  font-family: "Source Code Pro";
}